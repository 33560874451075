import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetTrackingHistory } from "../../api/AxiosInterceptors";
import UtilityClass from "../../utilities/UtilityClass";
import {
  CicrlesLoading,
  GreyBox,
  PageMainBox,
  TimeLineComponent,
  TimeLineItemComponent,
  fetchMethod,
  useGetLocationOrPathOrSearchParams,
} from "../../utilities/helpers/Helpers";

import img from "../../assets/images/AlisImages/analytics/activeCariiers.png";

export default function TrackingHistory() {
  const { path } = useGetLocationOrPathOrSearchParams();
  const [trackingData, setTrackingData] = useState({ loading: true, data: {} });

  useEffect(() => {
    (async () => {
      const { response } = await fetchMethod(
        () => GetTrackingHistory(path.replace("/", "")),
        setTrackingData
      );
      if (response && response.result) {
        setTrackingData((prev) => ({ ...prev, data: response.result }));
      }
    })();
  }, [path]);

  return (
    <>
      {trackingData.loading ? (
        <CicrlesLoading />
      ) : (
        <PageMainBox>
          <GreyBox
            sx={{
              maxWidth: 600,
              mx: "auto",
            }}
          >
            <Box className={"flex_center"}>
              {trackingData.data?.companyImage ? (
                <Avatar
                  src={trackingData.data?.companyImage}
                  sx={{ width: 80, height: 80 }}
                  variant={"square"}
                />
              ) : (
                <Typography variant="h3">
                  {trackingData.data?.clientCompanyName}
                </Typography>
              )}
            </Box>
            <TimeLineComponent>
              {trackingData.data?.history?.map((value, index, arr) => (
                <TimeLineItemComponent
                  key={index}
                  index={index}
                  data={trackingData.data}
                  TimelineOppositeContentData={
                    <>
                      <Typography variant="h5" fontWeight={400}>
                        {UtilityClass.convertUtcToLocal(value.CreatedOn)}
                      </Typography>
                    </>
                  }
                  TimelineContentData={
                    <>
                      <Typography variant="h5">
                        <span style={{ fontWeight: 400 }}>
                          Status changed to{" "}
                        </span>
                        {value.TrackingStatus}{" "}
                      </Typography>
                    </>
                  }
                />
              ))}
            </TimeLineComponent>
          </GreyBox>
        </PageMainBox>
      )}
    </>
  );
}
