import { Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../assets/images/Group 32.png";
import { styleSheet } from "../../assets/styles/style";
function TopNavBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const dispatch = useDispatch();
  return (
    <Box sx={styleSheet.topNavBar}>
      <Box sx={styleSheet.topNavBarLeft}>
        <Box component={"img"} width={140} src={Navbar} />
      </Box>
    </Box>
  );
}
export default TopNavBar;
