import React from "react";
import { styleSheet } from "../../assets/styles/style";
import BlockIcon from "@mui/icons-material/Block";

function UnauthorizedPage() {
  return (
    <div style={styleSheet.UnauthorizedPage}>
      <div>
        {" "}
        <center>
          <BlockIcon sx={{ fontSize: 140 }} color="error" />
        </center>
        <h1 style={styleSheet.UnauthorizedPageHeading}>Unauthorized Access</h1>
        <p style={styleSheet.UnauthorizedPageContent}>
          You don't have permission to access this page <br />
          For more detail contact admin
        </p>
      </div>
    </div>
  );
}
export default UnauthorizedPage;
