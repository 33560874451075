import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";

export default function ButtonComponent(props) {
  const {
    onClick,
    title,
    bg = "radial-gradient(100% 5102.04% at 100% 100%, #563AD5 0%, #442CAC 100%)",
    type,
    loading,
    p
  } = props;
  return (
    <LoadingButton
      loading={loading}
      type={type}
      sx={{
        p: p,
        color: "#fff",
        border: "1px solid rgba(30, 30, 30, 0.2) !important",
        textTransform: "capitalize !important",
        height: "29px",
        fontSize: "12px",
        background: bg,
        "&:disabled": {
          color: "gray",
          background: "rgba(30, 30, 30, 0.2) !important",
          border: "1px solid rgba(30, 30, 30, 0.2) !important",
        },
      }}
      variant="contained"
      onClick={onClick}
    >
      {title}
    </LoadingButton>
  );
}
