import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isNumericKeyInputPress: false,
};
const changeFlagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    changeNumericKeyInputPressFlag: (state, action) => {
      state.isNumericKeyInputPress = action.payload;
    },
  },
});
export const { changeNumericKeyInputPressFlag } = changeFlagsSlice.actions;
export default changeFlagsSlice.reducer;
