import axios from "axios";
import { getThisKeyCookie, setThisKeyCookie } from "../utilities/cookies";
import UtilityClass from "../utilities/UtilityClass";
import { EnumRoutesUrls } from "../utilities/enum";

let baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = `${process.env.REACT_APP_Dev_BaseUrl}/api/`;
} else if (process.env.NODE_ENV === "production") {
  console.log("Code for production environment");

  //#region staging url set
  baseURL = `${process.env.REACT_APP_Prod_BaseUrl}/api/`;
  //#endregion
}
export const Axios = axios.create({
  baseURL: baseURL,
});
export const reMoveStorageOnUnathorization = () => {
  // setLogoutCookie();
  window.location.reload();
};
// if (getThisKeyCookie("access_token")) {
//   Axios.interceptors.request.use((req) => {
//     req.headers.Authorization = `Bearer ${getThisKeyCookie("access_token")}`;
//     return req;
//   });
// }
///////////////////////////////
Axios.interceptors.request.use(
  async (config) => {
    const token = getThisKeyCookie("access_token");
    console.log(token);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          let body = {
            UserName: getThisKeyCookie("user_name"),
            RefreshToken: getThisKeyCookie("refresh_token"),
          };
          const res = await Axios.post(
            `/UserManagement/GetAccessTokenWithRefreshToken`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (res.data.isSuccess) {
            setThisKeyCookie("access_token", res.data.result.access_token);
            setThisKeyCookie("refresh_token", res.data.result.refresh_token);
            setThisKeyCookie("user_name", res.data.result.user_name);
            setThisKeyCookie("id_token", res.data.result.id_token);
            setThisKeyCookie(
              "expires_in",
              res.data.result.expires_in * 1000 + Date.now()
            );
          }
          if (Object.keys(res.data.errors).length > 0) {
            if (res.data.errors.NotAuthorizedException) {
              if (
                res.data.errors.NotAuthorizedException[0]?.includes("revoked")
              ) {
                //logout
                let body = {
                  accessToken: getThisKeyCookie("access_token"),
                };
                logout(body)
                  .then((res) => {
                    reMoveStorageOnUnathorization();
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              }
            } else {
              reMoveStorageOnUnathorization();
            }
            return;
          }
          return Axios(originalConfig);
        } catch (_error) {
          console.log(_error);
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403) {
        window.location.pathname = EnumRoutesUrls.NOTFOUND_403;
      }
    }
    return Promise.reject(err);
  }
);

//////////////////////////////
// console.log("getThisKeyCookie", getThisKeyCookie("access_token"));

// GetCarrierActivityWithDetail
export const GetCarrierActivityWithDetail = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetCarrierActivityWithDetail`, body);
};

export const GetCarrierStats = (filterModel) => {
  let createdFrom = UtilityClass.getFormatedDateWithoutTime(
    filterModel.createdFrom
  );
  let createdTo = UtilityClass.getFormatedDateWithoutTime(
    filterModel.createdTo
  );
  const body = {
    filterModel: {
      createdFrom: createdFrom,
      createdTo: createdTo,
    },
  };
  return Axios.post(`/Dashboard/GetCarrierStats`, body);
};
// GetTotalInprogressOrderCountWithCarrier
export const GetTotalInprogressOrderCountWithCarrier = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalInprogressOrderCountWithCarrier`, body);
};
// GetTotalCompletedOrderCountWithCarrier
export const GetTotalCompletedOrderCountWithCarrier = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalCompletedOrderCountWithCarrier`, body);
};
// GetTotalStoreCount
export const GetTotalStoreCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalStoreCount`, body);
};
// GetDelieveredOrderCount
export const GetDelieveredOrderCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetDelieveredOrderCount`, body);
};
// GetFulFillableOrderCount
export const GetFulFillableOrderCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetFulFillableOrderCount`, body);
};
// GetRegularOrderCount
export const GetRegularOrderCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetRegularOrderCount`, body);
};
// GetInProgressOrderCount
export const GetInProgressOrderCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetInProgressOrderCount`, body);
};
// GetReturnedOrderCount
export const GetReturnedOrderCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetReturnedOrderCount`, body);
};
// GetTotalCollected
export const GetTotalCollected = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalCollected`, body);
};
// GetTotalStockValue
export const GetTotalStockValue = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalStockValue`, body);
};

// GetTotalPurchaseStockValue
export const GetTotalPurchaseStockValue = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalPurchaseStockValue`, body);
};
// GetTotalUncollected
export const GetTotalUncollected = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalUncollected`, body);
};
// GetTotalCarriers
export const GetTotalCarriers = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalCarriers`, body);
};
// GetActiveCarriers
export const GetActiveCarriers = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  console.log(body);
  return Axios.post(`/Dashboard/GetActiveCarriers`, body);
};
// GetProductCounts
export const GetProductCounts = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetProductCounts`, body);
};
// GetAllProductVariantsCount
export const GetAllProductVariantsCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetAllProductVariantsCount`, body);
};
// GetTotalNoofOrdersPlacedCounts
export const GetTotalNoofOrdersPlacedCounts = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTotalNoofOrdersPlacedCounts`, body);
};
// GetToBeShippedCount
export const GetToBeShippedCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetToBeShippedCount`, body);
};
// GetToBePackedCount
export const GetToBePackedCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetToBePackedCount`, body);
};
// GetTopSellingItemsCount
export const GetTopSellingItemsCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetTopSellingItemsCount`, body);
};
// GetRegularShipmentCount
export const GetRegularShipmentCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetRegularShipmentCount`, body);
};
// GetLowStockItemsCount
export const GetLowStockItemsCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetLowStockItemsCount`, body);
};
// GetDeliveryRatioCount
export const GetDeliveryRatioCount = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Dashboard/GetDeliveryRatioCount`, body);
};
// GetAllCODCollectionPendingsMyCarrier
export const GetAllCODCollectionPendingsMyCarrier = (params) => {
  return Axios.post(`/Delivery/GetAllCODCollectionPendingsMyCarrier`, params);
};
// GetAllCODCollectionsMyCarrier
export const GetAllCODCollectionsMyCarrier = (filterModel) => {
  const body = {
    filterModel: filterModel,
  };
  return Axios.post(`/Delivery/GetAllCODCollectionsMyCarrier`, body);
};
export const ExcelExportCODCollectionPendingsMyCarrier = (body) => {
  return Axios.post(
    `/DriverAccount/ExcelExportCODCollectionPendingsMyCarrier`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
};
export const UpdateDriverReceivableStatusPaid = (body) => {
  return Axios.post(`/DriverAccount/UpdateDriverReceivableStatusPaid`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateExpense = (body) => {
  return Axios.post(`/DriverAccount/UpdateExpense`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const UpdateDriverReceivableStatusUnPaid = (body) => {
  return Axios.post(`/DriverAccount/UpdateDriverReceivableStatusUnPaid`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ExcelExportCODCollectionsMyCarrierQuery = (body) => {
  return Axios.post(
    `/DriverAccount/ExcelExportCODCollectionsMyCarrierQuery`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
};
//Client Endpoints

export const Login = (body) => {
  return Axios.post(`/UserManagement/Login`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetClientProfile = () => {
  return Axios.get(`/Client/GetClientProfile`);
};
// UpdateClient
export const UpdateClient = (
  clientImage,
  clientCompanyName,
  mobile,
  phone,
  countryId,
  regionId,
  cityId,
  streetAddress,
  clientName,
  regionTimeZoneId
) => {
  const body = {
    clientImage: clientImage,
    clientCompanyName: clientCompanyName,
    mobile: mobile,
    phone: phone,
    countryId: countryId,
    regionId: regionId,
    cityId: cityId,
    streetAddress: streetAddress,
    clientName: clientName,
    RegionTimeZoneId: regionTimeZoneId,
  };
  return Axios.post(`/Client/UpdateClient`, body);
};
export const UpdateClientImage = (body) => {
  return Axios.post(`/UserManagement/UploadClientImage`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UpdatePassword = (oldPassword, newPassword) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  return Axios.post(`/UserManagement/UpdatePassword`, body);
};

export const GetAccessTokenWithRefreshToken = (body) => {
  return Axios.post(`/UserManagement/GetAccessTokenWithRefreshToken`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const logout = (body) => {
  return Axios.post(`/UserManagement/Logout`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const Signup = (body) => {
  return Axios.post(`/UserManagement/Signup`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ConfirmSignUpUser = (body) => {
  return Axios.post(`/UserManagement/ConfirmSignUpUser`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CheckUsernameAvailability = (body) => {
  return Axios.post(`/UserManagement/CheckUsernameAvailability`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ResendConfirmationCode = (body) => {
  return Axios.post(`/UserManagement/ResendConfirmationCode`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const UploadClientImage = (body) => {
  return Axios.post(`/Client/UploadClientImage`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const ForgotPassword = (body) => {
  return Axios.post(`/UserManagement/ForgotPassword`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ConfirmForgotPassword = (body) => {
  return Axios.post(`/UserManagement/ConfirmForgotPassword`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Country Endpoints

export const GetAllCountry = () => {
  return Axios.get(`/Country/GetAllCountry`);
};

export const GetAllCitiesByRegionIds = (id) => {
  return Axios.get(`/Country/GetAllCitiesByRegionIds/?RegionIds=${id}`);
};
export const GetAllRegionbyCountryId = (id) => {
  return Axios.get(`/Country/GetAllRegionbyCountryId/?CountryId=${id}`);
};
export const GetAllRegionbyCountryIds = (id) => {
  return Axios.get(`/Country/GetAllRegionbyCountryIds/?CountryIds=${id}`);
};

export const GetCityByRegionId = (id) => {
  return Axios.get(`/Country/GetCityByRegionId/?RegionId=${id}`);
};
export const GetAllRegions = () => {
  return Axios.get(`/Country/GetAllRegions`);
};

// Store Endpoints

export const UploadStoreImage = (body) => {
  return Axios.post(`/Store/UploadStoreImage`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const UploadEmployeeImage = (body) => {
  return Axios.post(`/Employee/UploadEmployeeImage`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const GetNextEmployeeUserName = (body) => {
  return Axios.get(`/Employee/GetNextEmployeeUserName`);
};
export const CreateStore = (body) => {
  return Axios.post(`/Store/CreateStore`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CreateStoreChannel = (body) => {
  return Axios.post(`/Channel/CreateChannel`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllStores = (body) => {
  return Axios.post(`/Store/GetAllStores`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const DeleteStoreById = (body) => {
  return Axios.post(`/Store/DeleteStoreById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ActivateStoreById = (body) => {
  return Axios.post(`/Store/EnableStoreById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetStoreById = (body) => {
  return Axios.get(`/Store/GetStoreById?StoreId=${body}`);
};

export const UpdateStore = (body) => {
  return Axios.post(`/Store/UpdateStore`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetStoresForSelection = (body) => {
  return Axios.get(`/Store/GetStoresForSelection`);
};
export const GetChannelListByStoreIdForSelection = (body) => {
  return Axios.get(
    `/SaleChannel/GetSaleChannelByStoreIdForSelection?StoreId=${body}`
  );
};
export const GetAllSaleChannelByLookupIdForSelection = (body) => {
  return Axios.get(
    `/SaleChannel/GetAllSaleChannelByLookupIdForSelection?saleChannelLookupId=${body}`
  );
};
export const GetAllSaleChannelsByStoreId = (body) => {
  return Axios.get(`/SaleChannel/GetAllSaleChannelsByStoreId?StoreId=${body}`);
};
export const GetSaleChannelConfigById = (id) => {
  return Axios.get(
    `/SaleChannel/GetSaleChannelConfigById?SaleChannelConfigId=${id}`
  );
};
export const GetSaleChannelConfigForUpdateById = (id) => {
  return Axios.get(
    `/SaleChannel/GetSaleChannelConfigForUpdateById?SaleChannelConfigId=${id}`
  );
};

// Common Lookup Endpoints

export const GetAllReasons = () => {
  return Axios.get(`CommonLookup/GetAllLookupAdjustReason`);
};
export const GetAllCarrierTrackingStatusForSelection = () => {
  return Axios.get(
    `CommonLookup/GetAllCarrierTrackingStatusLookupForSelection`
  );
};
export const GetAllStationLookup = (body) => {
  return Axios.get(`/CommonLookup/GetAllStationLookup`);
};

export const GetAllProductOptionLookup = (body) => {
  return Axios.get(`/CommonLookup/GetAllProductOptionLookup`);
};

export const GetAllPaymentMethodLookup = (body) => {
  return Axios.get(`/CommonLookup/GetAllPaymentMethodLookup`);
};

export const GetAllOrderTypeLookup = (body) => {
  return Axios.get(`/CommonLookup/GetAllOrderTypeLookup`);
};
export const GetAllExpenseCategoryLookup = () => {
  return Axios.get(`CommonLookup/GetAllExpenseCategoryLookup`);
};
// Product Category Endpoints

export const GetAllProductCategoryLookup = () => {
  return Axios.get(`/ProductCategory/GetAllProductCategoryLookup`);
};
export const GetProductById = (productId) => {
  return Axios.get(`Product/GetProductById?ProductId=${productId}`);
};

export const CreateProductCategory = (body) => {
  return Axios.post(`/ProductCategory/CreateProductCategory`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateProductStockQuantityByReason = (body) => {
  return Axios.post(`Product/UpdateProductStockQuantityByReason`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteProductById = (body) => {
  return Axios.post(`/Product/DisableProductById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const EnableProductById = (body) => {
  return Axios.post(`/Product/EnableProductById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DisableProductStock = (body) => {
  return Axios.post(`/Product/DisableProductStock`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const EnableProductStock = (body) => {
  return Axios.post(`/Product/EnableProductStock`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const AddProductOption = (body) => {
  return Axios.post(`Product/QuickAddProductOption`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateProduct = (body) => {
  return Axios.post(`Product/UpdateProduct`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Product Endpoints

export const CreateProduct = (body) => {
  return Axios.post(`/Product/CreateProduct`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CheckUniqueProductStockSku = (id) => {
  return Axios.get(`/Product/CheckUniqueProductStockSku?Sku=${id}`);
};
export const CheckUniqueProductStockSkus = (Skus) => {
  return Axios.get(`/Product/CheckUniqueProductStockSkus?Skus=${Skus}`);
};

export const UploadProductFile = (body) => {
  return Axios.post(`/Product/UploadProductFile`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const GetAllProducts = (body) => {
  return Axios.post(`/Product/GetAllProducts`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetAllProductInventory = (body) => {
  return Axios.post(`/Product/GetAllProductInventory`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetProductStocksForSelection = (stationId, storeId) => {
  return Axios.get(
    `/Product/GetProductStocksForSelection?StationId=${
      stationId || 0
    }&storeId=${storeId || 0}`
  );
};

export const DownloadInventoryHistorySummaryExcel = (body) => {
  return Axios.post(`/Product/DownloadInventoryHistorySummaryExcel`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};

export const ExcelExportProductStockHistory = (body) => {
  return Axios.post(`/Product/ExcelExportProductStockHistory`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const ExcelExportProductInventorySummary = (body) => {
  return Axios.post(`/Product/ExcelExportProductInventorySummary`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetProductStockHistoryByStockId = (body) => {
  return Axios.post(`/Product/GetProductStockHistoryByStockId`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//#region ProductStation
export const GetProductStations = (body) => {
  return Axios.post(`/ProductStation/GetProductStations`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateProductStation = (body) => {
  return Axios.post(`/ProductStation/CreateProductStation`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateProductStation = (body) => {
  return Axios.post(`/ProductStation/UpdateProductStation`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const MakeDefaultProductStation = (body) => {
  return Axios.post(`/ProductStation/MakeDefaultProductStation`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteProductStationById = (body) => {
  return Axios.post(`/ProductStation/DeleteProductStationById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const ActiveProductStationById = (body) => {
  return Axios.post(`/ProductStation/ActiveProductStationById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetProductStationById = (id) => {
  return Axios.get(
    `/ProductStation/GetProductStationById?ProductStationId=${id}`
  );
};
//#endregion
// Order Endpoint

export const DeleteOrder = (body) => {
  return Axios.post(`/Order/DeleteOrders`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateOrder = (body) => {
  return Axios.post(`/Order/CreateOrder`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateOrderWithInvoice = (body) => {
  return Axios.post(`/Order/CreateOrderWithInvoice`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateOrderWithInvoice = (body) => {
  return Axios.post(`/Order/UpdateOrderWithInvoice`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateOrder = (body) => {
  return Axios.post(`/Order/UpdateOrder`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const RefreshCarrierStatus = (OrderId) => {
  const body = {
    OrderId: OrderId,
  };
  return Axios.post(`/Order/RefreshCarrierStatus`, body);
};

export const GetAllOrders = (
  createdFrom,
  createdTo,
  storeId,
  salePersonIds,
  orderTypeId,
  carrierId,
  fullFillmentStatusId,
  paymentStatusId,
  paymentMethodId,
  stationId,
  carrierAssign,
  searchParams,
  countryIds,
  regionIds,
  cityIds,
  carrierTrackingStatusIds
) => {
  const body = {
    filterModel: {
      createdFrom: createdFrom ? createdFrom : null,
      createdTo: createdTo ? createdTo : null,
      start: 0,
      length: 1000,
      search: searchParams,
      sortDir: "desc",
      sortCol: 0,
    },
    storeId: storeId,
    orderTypeId: orderTypeId,
    carrierId: carrierId,
    fullFillmentStatusId: fullFillmentStatusId,
    paymentStatusId: paymentStatusId,
    orderRequestVia: 0,
    paymentMethodId: paymentMethodId,
    stationId: stationId,
    carrierAssign: carrierAssign,
    salePersonIds: salePersonIds,
    countryIds,
    regionIds,
    cityIds,
    carrierTrackingStatusIds: carrierTrackingStatusIds,
  };
  return Axios.post(`/Order/GetAllOrders`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const MoveDashboardStatusFromOneTabToAnother = (body) => {
  return Axios.post(`/Shipment/MoveDashboardStatusFromOneTabToAnother`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateShipmentTabDisplayOrder = (body) => {
  return Axios.post(`/Shipment/UpdateShipmentTabDisplayOrder`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateShipmentGridColumn = (body) => {
  return Axios.post(`/Shipment/CreateShipmentGridColumn`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const ExcelExportOrders = (body) => {
  return Axios.post(`/Order/ExcelExportOrders`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob", // important
  });
};
export const ExcelExportShipments = (body) => {
  return Axios.post(`/Shipment/ExcelExportShipments`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob", // important
  });
};
export const GetOrderFileByOrderTypeId = (id) => {
  return Axios.post(`/Order/GetOrderFileByOrderTypeId?OrderTypeId=${id}`);
};

export const UploadFileRegular = (body) => {
  return Axios.post(`/Order/UploadFileRegular`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const AssignToCarrier = (body) => {
  return Axios.post(`/Order/AssignToCarrier`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetOrderById = (orderId) => {
  return Axios.get(`Order/GetOrderById?OrderId=${orderId}`);
};
//assign in house
export const CreateDeliveryTask = (body) => {
  return Axios.post(`/Delivery/CreateDeliveryTask`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const BatchOrderFulfillment = (body) => {
  return Axios.post(`/Order/BatchOrderFulfillment`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const BatchUpdateOrderStatus = (body) => {
  return Axios.post(`/Order/BatchUpdateOrderStatus`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UploadFileFullfilable = (body) => {
  return Axios.post(`/Order/UploadFileFullfilable`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const UpdateOrderAmount = (body) => {
  return Axios.post(`/Order/UpdateOrderAmount`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const DeleteOrderItemById = (body) => {
  return Axios.post(`/Order/DeleteOrderItemById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//Carriers Endpoint
export const getAllCarriers = (body) => {
  return Axios.post(`/Carrier/GetAllCarriers`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const activateCarrier = (body) => {
  return Axios.post(`/Carrier/CreateActiveCarrier`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllActivedCarrier = (body) => {
  return Axios.post(`/Carrier/GetAllActiveCarrier`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllDriverCtssetting = (body) => {
  return Axios.get(`/Driver/GetAllDriverCtssetting`);
};
export const CreateDriverStatues = (body) => {
  return Axios.post(`/Driver/CreateDriverCtssetting`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteDriverCtssetting = (body) => {
  return Axios.post(`/Driver/DeleteDriverCtssetting`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getInputRequiredConfig = (id) => {
  return Axios.get(`/Carrier/GetInputRequiredConfigById?CarrierId=${id}`);
};
export const DeleteActiveCarrierById = (body) => {
  return Axios.post(`/Carrier/DeleteActiveCarrierById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetCarrierWebHookUrlByCarrierId = (id) => {
  return Axios.get(`/Carrier/GetCarrierWebHookUrlByCarrierId?CarrierId=${id}`);
};
export const UpdateCarrierAlias = (body) => {
  return Axios.post(`/Carrier/UpdateCarrierAlias`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CheckDuplicationCarrierAlias = (body) => {
  return Axios.post(`/Carrier/CheckDuplicationCarrierAlias`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UnAssignFromCarrier = (body) => {
  return Axios.post(`/Carrier/UnAssignFromCarrier`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//Shipment Endpoints

export const GetShipmentTabsCountConfig = () => {
  return Axios.get(`/Shipment/GetShipmentTabsCountConfig`);
};

export const GetAllShipmentsByDriverReceivableId = (body) => {
  return Axios.post(`/Shipment/GetAllShipmentsByDriverReceivableId`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const ExportPDFShipmentsByDriverReceivableId = (body) => {
  return Axios.post(`/Shipment/ExportShipmentsByDriverReceivableId`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetPDFCarrierReturnReportById = (body) => {
  return Axios.post(`/Shipment/GetPDFCarrierReturnReportById`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetShipments = (body) => {
  return Axios.post(`/Shipment/GetShipments`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetShipmentTabsCount = (body) => {
  return Axios.post(`/Shipment/GetShipmentTabsCount`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//Account Endpoints

export const GetCarrierSettlementSamplefile = () => {
  return Axios.get(`/Account/GetCarrierSettlementSamplefile`);
};
export const GetAllCarrierTrackingStatusLookupForSelection = () => {
  return Axios.get(`/Common/GetAllCarrierTrackingStatusLookupForSelection`);
};
export const GetAllRegionTimeZone = () => {
  return Axios.get(`/CommonLookup/GetAllRegionTimeZone`);
};

export const GetAllCarrierPaymentSettlements = (body) => {
  return Axios.post(`/Account/GetAllCarrierPaymentSettlements`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetAllCODPendings = (body) => {
  return Axios.post(`/Account/GetAllCODPendings`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const UplaodCarrierSettlementFile = (body) => {
  return Axios.post(`/Account/UplaodCarrierSettlementFile`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const CreateCarrierPaymentSettlementWithFile = (body) => {
  return Axios.post(`/Account/CreateCarrierPaymentSettlement`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateCarrierPaymentSettlement = (body) => {
  return Axios.post(`/Account/CreateCarrierPaymentSettlement`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
// GetShipmentInfoByOrderNo
export const GetShipmentInfoByOrderNo = (orderNo) => {
  const body = {
    orderNo: orderNo,
  };
  return Axios.post(`/Shipment/GetShipmentInfoByOrderNo`, body);
};
export const GetOrderItemByOrderNo = (param) => {
  return Axios.get(`/Order/GetOrderItemByOrderNo?OrderNo=${param}`);
};
export const GetActiveCarriersForSelection = () => {
  return Axios.get(`/Carrier/GetActiveCarriersForSelection`);
};

export const GetAllFullFillmentStatusLookup = () => {
  return Axios.get(`/CommonLookup/GetAllFullFillmentStatusLookup`);
};

export const GetAllPaymentStatusLookup = () => {
  return Axios.get(`/CommonLookup/GetAllPaymentStatusLookup`);
};
export const UpdateCustomerEmail = (body) => {
  return Axios.post(`/Order/UpdateCustomerEmailAddress`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const SendNotificationToCustomer = (body) => {
  return Axios.post(`/Notification/SendNotificationToCustomer`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetWayBillsByOrderNos = (body) => {
  return Axios.post(`/Order/GetWayBillsByOrderNos`, body, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetOrderInvoiceByOrderNos = (body) => {
  return Axios.get(`/Order/GetOrderInvoiceByOrderNos?OrderNos=${body}`, {
    responseType: "blob",
  });
};
export const GetStripeInvoiceByUrl = (stripUrl) => {
  return Axios.get(`${stripUrl}`, {
    responseType: "blob",
  });
};
export const CreateInvoiceByOrderNumber = (body) => {
  return Axios.post(`/Order/CreateInvoiceByOrderNumber`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetValidateClientPPActivate = (body) => {
  return Axios.get(`/Client/GetValidateClientPPActivate`);
};
export const GetUniqueAutogeneratedSku = (body) => {
  return Axios.get(`/Product/GetUniqueAutogeneratedSku`);
};
export const ExcelExportProductInventory = (body) => {
  return Axios.post(`/Product/ExcelExportProductInventory`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob", // important
  });
};
export const GetAllStoreChannel = (body) => {
  return Axios.post(`/Channel/GetAllChannel`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllChannelByStoreId = (body) => {
  return Axios.get(`/Channel/GetAllChannelByStoreId?StoreId=${body}`);
};
// inventory sale
export const GetAllInventorySales = (body) => {
  return Axios.post(`/Product/GetAllInventorySales`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const ExcelExportInventorySales = (body) => {
  return Axios.post(`/Product/ExcelExportInventorySales`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetInventorySalesSummary = (body) => {
  return Axios.post(`/Product/GetInventorySalesSummary`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const ExcelExportInventorySalesSummary = (body) => {
  return Axios.post(`/Product/ExcelExportInventorySalesSummary`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetPDFInventorySales = (body) => {
  return Axios.post(`/Product/GetPDFInventorySales`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetPDFInventorySalesSummary = (body) => {
  return Axios.post(`/Product/GetPDFInventorySalesSummary`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
////carrier return
export const GetReturnReportSampleFile = (body) => {
  return Axios.get(`/ReturnReport/GetReturnReportSampleFile`);
};
export const UplaodReturnReportFile = (body) => {
  return Axios.post(
    `/ReturnReport/GetOrderDetailByUplaodReturnReportFile`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const CreateMyCarrierReturnReport = (body) => {
  return Axios.post(`/Delivery/CreateMyCarrierReturnReport`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CreateCarrierReturnReport = (body) => {
  return Axios.post(`/ReturnReport/CreateCarrierReturnReport`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetOrderDetailForReturnReport = (body) => {
  return Axios.post(`/ReturnReport/GetOrderDetailForReturnReport`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetReturnRerports = (body) => {
  return Axios.post(`/ReturnReport/GetReturnRerports`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetShipmentsByReturnRerportId = (body) => {
  return Axios.get(
    `/ReturnReport/GetShipmentsByReturnReportId?CarrierRRId=${body}`
  );
};
export const ExcelExportReturnReportById = (body) => {
  return Axios.get(
    `/ReturnReport/ExcelExportReturnReportById?CarrierRrid=${body}`,
    { responseType: "blob" }
  );
};

/////delivery task
export const GetAllDeliveryTask = (body) => {
  return Axios.post(`/Delivery/GetAllDeliveryTask`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const BatchOutScanDeliveryTask = (body) => {
  return Axios.post(`/Delivery/BatchOutScanDeliveryTask`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const RevertDeliveryTaskByOrderNos = (body) => {
  return Axios.post(`/Delivery/RevertDeliveryTaskByOrderNos`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllDeliveryNote = (body) => {
  return Axios.post(`/Delivery/GetAllDeliveryNote`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetDeliveryNoteDetailForDebrief = (body) => {
  return Axios.post(`/Delivery/GetDeliveryNoteDetailForDebrief`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteMyCarrierReturnReport = (body) => {
  return Axios.post(`/Delivery/DeleteMyCarrierReturnReport`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const RevertDeliveryNoteDetailByOrderId = (body) => {
  return Axios.post(`/Delivery/RevertDeliveryNoteDetailByOrderId`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CompleteDeliveryNote = (body) => {
  return Axios.post(`/Delivery/CompleteDeliveryNote`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllPendingForReturnShipments = (body) => {
  return Axios.post(`/Delivery/GetAllPendingForReturnShipments`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllMyCarrierReturnReports = (body) => {
  return Axios.post(`/Delivery/GetAllMyCarrierReturnReports`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetPDFMyCarrierReturnReport = (body) => {
  return Axios.post(`/Delivery/GetPDFMyCarrierReturnReport`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const UpdateOrderStatusOnDebrief = (body) => {
  return Axios.post(`/Delivery/UpdateOrderStatusOnDebrief`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateDeliveryNoteInOperation = (body) => {
  return Axios.post(`/Delivery/UpdateDeliveryNoteInOperation`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateDeliveryNoteDetailPendingForReturnStatus = (body) => {
  return Axios.post(
    `/Delivery/CreateDeliveryNoteDetailPendingForReturnStatus`,
    body
  );
};
export const GetPDFDeliveryRunSheet = (body) => {
  return Axios.get(`/Delivery/GetPDFDeliveryRunSheet?DeliveryNoteId=${body}`, {
    responseType: "blob",
  });
};
//////////employee
export const GetAllEmployees = (body) => {
  return Axios.post(`/Employee/GetAllEmployees`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const CreateEmployee = (body) => {
  return Axios.post(`/Employee/CreateEmployee`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateEmployee = (body) => {
  return Axios.post(`/Employee/UpdateEmployee`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteEmployee = (body) => {
  return Axios.get(`/Employee/DeleteEmployee?EmployeeId=${body}`);
};
export const GetEmployeeById = (body) => {
  return Axios.get(`/Employee/GetEmployeeById?EmployeeId=${body}`);
};
export const GetAllSalePersonForSelection = (body) => {
  return Axios.get(`/Employee/GetAllSalePersonForSelection`);
};

export const GetAllEmployeesForSelection = (body) => {
  return Axios.get(`/Employee/GetAllEmployeesForSelection`);
};
export const GetGenderForSelection = (body) => {
  return Axios.get(`/Employee/GetGenderForSelection`);
};
///////////driver
export const CreateDriver = (body) => {
  return Axios.post(`/Driver/CreateDriver`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetDriversForSelection = (body) => {
  return Axios.get(`/Driver/GetDriversForSelection`);
};
export const GetAllDrivers = (body) => {
  return Axios.post(`/Driver/GetAllDrivers`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteDriver = (body) => {
  return Axios.post(`/Driver/DeleteDriver`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllDriverReceivable = (body) => {
  return Axios.post(`/DriverAccount/GetAllDriverReceivable`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllDriverExpense = (body) => {
  return Axios.post(`/DriverAccount/GetAllDriverExpense`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
////////////expense
export const GetPDFDriverExpenseReport = (body) => {
  return Axios.post(`/Expense/GetPDFDriverExpenseReport`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const ExcelExportDriverExpense = (body) => {
  return Axios.post(`/DriverAccount/ExcelExportDriverExpense`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const CreateExpenseCategory = (body) => {
  return Axios.post(`/Expense/CreateExpenseCategory`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetExpenseById = (body) => {
  return Axios.get(`/DriverAccount/GetExpenseById?ExpenseId=${body}`);
};
export const GetAllExpenseAccount = (body) => {
  return Axios.post(`/Expense/GetAllExpenseAccount`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
////////platform
export const GetAllPlatformForSelection = (body) => {
  return Axios.get(`/Platform/GetAllPlatformLookupForSelection`);
};
export const GetAllPlatformConfig = (body) => {
  return Axios.post(`/Platform/GetAllPlatformConfig`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CreatePlatformConfig = (body) => {
  return Axios.post(`/Platform/CreatePlatformConfig`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

//////SaleChannel
export const GetSaleChannelByStoreIdForSelection = (id) => {
  return Axios.get(
    `/SaleChannel/GetSaleChannelByStoreIdForSelection?StoreId=${id}`
  );
};

export const CreateSaleChannelConfig = (body) => {
  return Axios.post(`/SaleChannel/CreateSaleChannelConfig`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const SaleChannelInventorySync = (body) => {
  return Axios.post(`/SaleChannel/SaleChannelInventorySync`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const UpdateSaleChannelConfig = (body) => {
  return Axios.post(`/SaleChannel/UpdateSaleChannelConfig`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllSaleChannelConfig = (body) => {
  return Axios.post(`/SaleChannel/GetAllSaleChannelConfig`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const SaleChannelOrderPreProcessor = (body) => {
  return Axios.post(`/SaleChannel/SaleChannelOrderPreProcessor`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const SaleChannelProductPreProcessor = (body) => {
  return Axios.post(`/SaleChannel/SaleChannelProductPreProcessor`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const SaleChannelProductPostProcessor = (body) => {
  return Axios.post(`/SaleChannel/SaleChannelProductPostProcessor`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const SaleChannelOrderPostProcessor = (body) => {
  return Axios.post(`/SaleChannel/SaleChannelOrderPostProcessor`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllSaleChannelLookupForSelection = (body) => {
  return Axios.get(`/SaleChannel/GetAllSaleChannelLookupForSelection`);
};

////// sms process
export const GetAllSMSActivate = (body) => {
  return Axios.post(`/Sms/GetAllSMSActivate`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllSMSLookupForSelection = (body) => {
  return Axios.get(`/Sms/GetAllSMSLookupForSelection`);
};
export const ActivateSmsProcess = (body) => {
  return Axios.post(`/Sms/ActivateSmsProcess`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeleteSMSActivate = (body) => {
  return Axios.post(`/Sms/DeleteSMSActivate`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetSMSActivateById = (id) => {
  return Axios.get(`/Sms/GetSMSActivateById?PpactivateId=${id}`);
};
//// payment process
export const GetAllPPActivate = (body) => {
  return Axios.post(`/PaymentProcess/GetAllPPActivate`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllPPLookupForSelection = (body) => {
  return Axios.get(`/PaymentProcess/GetAllPPLookupForSelection`);
};
export const ActivatePaymentProcess = (body) => {
  return Axios.post(`/PaymentProcess/ActivatePaymentProcess`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const DeletePPActivate = (body) => {
  return Axios.post(`/PaymentProcess/DeletePPActivate`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetPPActivateById = (id) => {
  return Axios.get(`/PaymentProcess/GetPPActivateById?PPactivateId=${id}`);
};
export const ExcekExportLowQuantityProductStock = (body) => {
  return Axios.post(`/Product/ExcekExportLowQuantityProductStock`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};
export const GetAllLowQuantityProductStock = (body) => {
  return Axios.post(`/Product/GetAllLowQuantityProductStock`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const MarkCarrierSettlementPaid = (body) => {
  return Axios.post(`/Account/MarkCarrierSettlementPaid`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// =============postman
export const DownloadApiPostManCollection = (body) => {
  return Axios.get(`/CommonLookup/DownloadApiPostManCollection`, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
};

export const DeleteShipmentTab = (body) => {
  return Axios.post(`/Shipment/DeleteShipmentGridColumn`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const UpdateShipmentTab = (body) => {
  return Axios.post(`/Shipment/UpdateShipmentGridColumn`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetAllCarrierWithServiceAndLocation = (body) => {
  return Axios.post(`/Carrier/GetAllCarrierWithServiceAndLocation`, body);
};
export const GetAllDeliveryService = (body) => {
  return Axios.get(`Carrier/GetAllDeliveryService`);
};
export const GetCarrierWithServiceAndLocationByCarrierId = (id) => {
  return Axios.get(
    `Carrier/GetCarrierWithServiceAndLocationByCarrierId?CarrierId=${id}`
  );
};

export const GetAllClientUserRole = (body) => {
  return Axios.get(`Permission/GetAllClientUserRole`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetAllClientRolePermissionGroup = (id) => {
  return Axios.get(
    `Permission/GetAllClientRolePermissionGroup?clientUserRoleId=${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const AddUpdateClientRolePermissionGroup = (body) => {
  return Axios.post(`Permission/AddUpdateClientRolePermissionGroup`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetTrackingHistory = (apiKey) => {
  const body = {
    apiKey: apiKey,
  };
  return Axios.post(`Tracking/GetTrackingHistory`, body);
};
