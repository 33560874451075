
import  Cookies  from 'js-cookie';

/* ------------------------------  SET COOKIE DATA FUNCTION  -------------------------------- */
export const setThisKeyCookie = (key, value) => {
    Cookies.set(key, value);
};

/* ------------------------------  GET COOKIE DATA FUNCTION  -------------------------------- */
export const getThisKeyCookie = (key) => {
    return Cookies.get(key);
};

/* ------------------------------  REMOVE COOKIE DATA FUNCTION  -------------------------------- */
export const removeThisKeyCookie = (key) => {
    return Cookies.remove(key);
};

/* ------------------------------  SET POLICY COOKIE DATA FUNCTION  -------------------------------- */
export const setPolicyCookie = () => {
    let expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear(), expireDate.getMonth() + 6);
    Cookies.set('cookiepolicy', 'true', expireDate);
};

/* ------------------------------  GET POLICY COOKIE DATA FUNCTION  -------------------------------- */
export const getPolicyCookie = () => {
    return Cookies.get('cookiepolicy');
};