import { configureStore } from "@reduxjs/toolkit";
import LanguageReducer from "./language";
import UserProfileSideBarReducer from "./changeSideBar";
import UserProfileImgChangeReducer from "./changeProfileImg";
import UserRoleReducer from "./userRole";
import changeFlagsSlice from "./changeFlags";
export const store = configureStore({
  reducer: {
    LanguageReducer: LanguageReducer,
    UserProfileSideBarReducer: UserProfileSideBarReducer,
    UserProfileImgChangeReducer: UserProfileImgChangeReducer,
    UserRoleReducer: UserRoleReducer,
    changeFlagsReducer: changeFlagsSlice,
  },
});
