import { Box } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoutes from "./components/authRoutes";
import LoginSignupRoutes from "./components/loginSignupRoutes";
import NotFound from "./components/notFound";
import NotFound403 from "./components/notFound/NotFound403.js";
import TrackingHistoryPage from "./pages/tracking/TrackingHistory.js";
import { EnumRoutesUrls } from "./utilities/enum";
function App() {
  return (
    <Box>
      <BrowserRouter>
        {" "}
        <Routes>
          <Route
            path={window.location.pathname}
            element={
              <LoginSignupRoutes>
                <TrackingHistoryPage />
              </LoginSignupRoutes>
            }
          />

          <Route
            path="*"
            element={
              <PrivateRoutes type="404">
                <NotFound />
              </PrivateRoutes>
            }
          />
          <Route
            path={EnumRoutesUrls.NOTFOUND_403}
            element={
              <PrivateRoutes type="403">
                <NotFound403 />
              </PrivateRoutes>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </Box>
  );
}

export default App;
